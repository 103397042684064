@import url(https://fonts.googleapis.com/css?family=Work+Sans);
body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: DM Sans, sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
}
.MuiSlider-thumb {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAsSURBVHgB7dAxAQAABETRu+b6yCGTlQAmbPwAf3hUc8E3ifcMiWZJVQ+3DQOzowshZpuMVAAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
}

.MuiSlider-root > .MuiSlider-thumb ~ .MuiSlider-thumb {
    margin-bottom: -14px !important;
}
.customPadding{
    padding: 10px 0 !important
}
